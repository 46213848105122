.progressbar {
  padding: 0;
  overflow: hidden;
  margin-bottom: 0;
}

.progressbar li {
  list-style-type: none;
  float: left;
  width: 30%;
  position: relative;
  text-align: center;
  right: 0;
  color: #2E3032;
}
.progressbar li:before{
  content: attr(data-number);
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 25px;
  border: 3px solid #D8DAE5;
  color: #474D66;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: #D8DAE5;
}
.progressbar li:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #D8DAE5;
  top: 13px;
  left: -50%;
  z-index: -30;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active:before {
  color: white !important;
  border-color: #E42312;
  background: #E42312;
}
.progressbar li.current:before {
  color: #A40917 !important;
  border-color: #E42312;
  background: white;
}
.progressbar li.current.active:before {
  background-color: white;
  border-color: #E42312;
  color: #E42312 !important;
}
.progressbar li.current {
  font-weight: bold;
}
.progressbar li.done:before {
  border-color: #E42312;
  border-style: solid;
  background-color: #E42312;
  color: white;
}
.progressbar li.done.current:before {
  border-color: #E42312 !important;
  background-color: white;
}
.progressbar li.done + li:after {
  background-color: #E42312;
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.scrolling-wrapper,
.scrolling-wrapper-flexbox {
  height: 80px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 1200px) {
  .progressbar li {
    width: 180px;
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  .progressbar li {
    width: 150px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .progressbar li {
    width: 60%;
  }
}