.accordion-button {
  background-color: white !important;
}

.accordion-button:focus {
  box-shadow: none;
  color: black;
}

.card-header {
  background-color: white;
}

.icon:active {
  transform: rotate(180deg);
  transition: 1s;
}

.description {
  margin-left: 60px;
}

.contact-us {
  color: red;
  font-weight: bold;
}

.faq-apps-content-margin {
  margin: 1rem 4rem 2.5rem 4rem;
}

.link-contact {
  text-decoration: none;
  color: red;
}