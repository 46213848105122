.ckeditor-error {
    border: 1px solid #de1b1b !important;
}

.ckeditor-error.border-radius {
    border: 1px solid #de1b1b !important;
    border-radius: 6px;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), url(../../fonts/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf) format('truetype');
}