.nav-item.dropdown #language {
  padding: 2.5vh;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 21.6px !important;
}

.text-navbar-item {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 21.6px !important;
  padding: 2.2vh 10px 2.2vh 10px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.text-navbar-item:hover,
.nav-item.dropdown #language:hover {
  background-color: rgba(238, 240, 247, 1) !important;
}

.navbar-body {
  padding: 0 !important;
}

#help::after,
#language::after,
#about::after {
  border: none !important;
  content: '' !important;
  vertical-align: middle !important;
}

.text-button-navbar {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  border-radius: 4px !important;
  border: 0 !important;
}

.text-button-navbar.login {
  color: #2E3032 !important;
  box-shadow: 0px 0px 0px 2px #E3E5EA !important;
  background: #FFFFFF !important;
}

.text-button-navbar.login:hover {
  background: #E3E5EA !important;
}

.text-button-navbar.register {
  color: #E42313 !important;
  box-shadow: 0px 0px 0px 2px #EEAFAF !important;
  background: #FDF7F7 !important;
}

.text-button-navbar.register:hover {
  background: #EEAFAF !important;
}

.dropdown-menu.show[aria-labelledby='help'],
.dropdown-menu.show[aria-labelledby='language'],
.dropdown-menu.show[aria-labelledby='about'] {
  border-radius: 0;
  top: 75px;
}

@media (max-width: 991px) {
  .navbar-body {
    min-height: 78px !important;
  }

  .text-navbar-item {
    display: inline !important;
  }
}

@media (min-width: 992px) {
  .navbar-body {
    height: 78px !important;
  }
}