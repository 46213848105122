.landing-apps-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2E3032;
}

.landing-apps-container-width {
  width: 25%;
  padding: 0 0 0 0;
}

.landing-apps-content-margin {
  margin: 0 5rem 5rem 5rem !important;
}

#newsHtml table {
  width: 100% !important;
}

@media (max-width: 800px) {
  .landing-apps-container-width {
    width: 100%;
    padding: 0 20vw 0 20vw;
  }

  .landing-apps-content-margin {
    margin: auto !important;
  }
}