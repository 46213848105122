.container-image-bg-login {
    display: inline;
  }
  
  .image-bg-login {
    height: 93vh !important;
    width: 100vw;
  }
  
  .image-caption {
    font-weight: 700;
    font-size: 60px;
    line-height: 72px;
    color: #FFFFFF;
  }
  
  .image-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
  }
  
  .button-v2 {
    background-color: #F9E4E4 !important;
    color: #BF2600 !important;
    border: 0 !important;
    border-radius: 4px !important;
    padding-top: 13px;
    padding-bottom: 13px;
  }
  
  .info-v2 {
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    color: #727272;
  }
  
  @media (max-width: 992px) {
    .container-image-bg-login {
      display: none !important;
    }
  }
  
  .section-line {
    border: 1px solid #E1E1E1;
    margin-left: auto;
    opacity: 1;
    flex: auto;
  }
  
  .section-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #A3A4A9;
  }
  
  .captcha-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(92, 94, 97, 0.7);
    flex-direction: column;
  }
  
  .captcha-container.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s ease-in, visibility 0.2s ease-in;
  }
  
  .captcha-container.hide {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  }
  
  .font-forgot-password {
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #E42312;
  }
  
  .font-remember-me {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8C8F93;
  }
  
  .font-sign-up {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #727272;
  }
  
  .font-title-page-login {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #2E3032;
  }
  
  .font-v2 {
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #BF2600 !important;
  }
  
  .form-label-login {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: black;
  }
  
  #check-login {
    width: 18px !important;
    height: 18px !important;
  }

  #check-login-TnC {
    width: 18px !important;
    height: 18px !important;
  }
  
  .radio-button-custom-container {
    background-color: rgba(247, 248, 249, 1);
    border-radius: 4px;
    padding: 8px, 16px;
  }
  
  .custom-background-icon {
    background-color: rgba(238, 240, 247, 1);
    border-radius: 50px;
  }
  
  .custom-a-tag-style {
    cursor: pointer;
  }