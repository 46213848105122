.landing-apps-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2E3032;
}

.landing-apps-container-width {
  width: 25%;
  padding: 0 0 0 0;
}

.landing-apps-news-margin {
  margin: 0 5rem 5rem 10rem;
}

.landing-apps-content-lol {
  margin: 0 5rem 10rem 10rem;
}

.image-main-skeleton{
  margin: 0 5rem 5rem 10rem;
}

.text-description-news {
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
}

.text-description-news-many {
  height: 3.8rem;
  line-height: 1.5rem;
  overflow: hidden;
}

@media (max-width: 991px) {
  .landing-apps-container-width {
    width: 100%;
    padding: 0 20vw 0 20vw;
  }

  .landing-apps-news-margin {
    margin: 0 5rem 0 5rem !important;
  }
  .image-main-skeleton{
    margin: auto;
  }
}