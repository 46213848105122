.container-image {
  background: linear-gradient(103deg, rgba(238, 240, 247, 1) 85.45%, rgba(211, 59, 59, 0.28) 111.34%);  max-width: 100vw;
  height: 40vh;
  background-size: cover;
  background-blend-mode: hard-light;
  color: white;
}

.title-custom-header {
  color: black;
}

.description-custom-header {
  color: var(--Tertiary-Grayscale---60, #8C8F93);
}