.navbar-active {
    background-color: #ea3624;
}

.navbar-child {
    transition: transform 500ms;
}

.navbar-child:hover{
    transform: translateX(1rem) ;
    color: white;

}

.navbar-child-text {
    color: grey;
}
.navbar-child:hover span {
    color: white;
}