.contentVideo {
    margin: min(5vw, 10rem);
}

.contentText {
    margin-top: 10vh;
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.about-vision {
    background-image: linear-gradient(to right, #F1F7FF 30.65%, rgba(255, 255, 255, 0)),
        var(--img);
    max-width: 100vw;
    background-size: cover;
    background-blend-mode: hard-light;
}

.about-video {
    padding: 100px 0 100px 0;
}

.about-title {
    font-size: large;
}

.about-apps-content-margin {
    margin: 0 10rem 10rem 10rem;
}

.test {
    margin: 11rem 20vw 20vw 20vw;
}

@media (max-width: 800px) {
    .about-apps-container-width {
        width: 100%;
        padding: 0 20vw 0 20vw;
    }

    .about-apps-content-margin {
        margin: 0 0 5rem 0;
    }
}

.about-items {
    max-height: 120px;
    border: 1px solid #F1F7FF;
    display: flex;
    overflow-y: auto;
}

.about-items::-webkit-scrollbar {
    width: 0;
}

.carousel {
    margin-left: 6rem;
}

.card {
    max-width: 20vw;
    height: 40vh;
    margin-right: 12px;
    border-color: transparent;
    border: 0 !important
}


.see-more {
    height: calc(11rem - 0.5rem);
    border-radius: 12px;
    border-color: transparent;
    background-color: #F1F3F6;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
}

.view-detail-button {
    color: red;
}

.button-arrow {
    width: 0.8rem;
    height: 0.8rem;
}

.text-see-more {
    color: black;
}
