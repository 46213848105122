@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./fonts/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 90vw !important;
}

@media (max-width: 575px) {
  .flex-xs-column {
    flex-direction: column !important;
  }
}

.banner-caption-container {
  margin: 0 0 0 10vw;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: baseline;
  max-width: 501px;
}

.banner-caption {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
}

.banner-description {
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  margin-bottom: 3vh;
}

.banner-button {
  background-color: #E42312 !important;
  font-size: 18px !important;
  border-radius: 4px !important;
}

.banner-button-out {
  border-width: 2px !important;
  border-color: #E42312 !important;
  font-size: 18px !important;
  border-radius: 4px !important;
  color: #E42312 !important;
}

.banner-button-disable {
  background-color: #D9DDE3 !important;
  font-size: 18px !important;
  border-color: #D9DDE3 !important;
}

.text-red {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #E42312;
  text-decoration: none;
}

.image-cover-fluid {
  background-image: var(--img);
  max-width: 100vw;
  height: auto;
  background-size: cover;
}

.image-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  max-width: 100%;
  height: auto;
  padding: 10vh;
}

.button-secondary {
  background-color: transparent !important;
  color: #E42312 !important;
  border: 0 !important;
}

@media (max-width: 991px) {
  .banner-caption-container {
    margin: 0 0 0 0;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 575px) {
  .image-overlay {
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    max-width: 100%;
    height: auto;
    padding: 5 vh;
  }
}

.breadcrumb-item+.breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">") !important;
}

.react-tel-input .flag-dropdown {
  background-color: white !important;
  border-right: 0 !important;
}

.row-container-form {
  height: 91.5vh !important;
}

.col-container-form {
  height: 100% !important;
  overflow-y: scroll;
}

.p-regis {
  padding: 2rem !important;
}

.terms-and-condition {
  height: 300px;
  overflow: auto;
  background-color: #F6F7FC;
}

::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
}

.collapse-container {
  visibility: hidden;
  max-height: 0;
  opacity: 0;
  transition: all .4s linear;
}

.collapse-container.show {
  visibility: visible;
  max-height: 1000px;
  opacity: 1;
  transition: all .4s linear;
}

.custom-tabs {
  background-color: #F8F9FA;
}

.custom-tabs .custom-tab {
  background-color: #FFF;
  border: 0.5px solid #C8CCD2;
  color: #5C5E61;
  border-bottom: 2px solid #C8CCD2;
}

.custom-tabs .custom-tab.left {
  border-top-right-radius: 0;
}

.custom-tabs .custom-tab.right {
  border-top-left-radius: 0;
}

.custom-tabs .custom-tab.middle {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.custom-tabs .custom-tab.active {
  background-color: #F9E4E4;
  border: 0.5px solid #E42312;
  color: #E42312;
  border-bottom: 2px solid #E42312;
}

.custom-checkbox {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #E42312;
  width: 20px;
  height: 20px;
  border: 1.67px solid #8C8F93;
  border-radius: 5px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.custom-checkbox::before {
  content: "";
  width: 12px;
  height: 10px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #E42312;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.custom-checkbox:checked:before {
  transform: scale(1);
}

.custom-checkbox:checked {
  border: 1.67px solid #E42312;
}

.custom-checkbox:disabled {
  color: #959495;
  cursor: not-allowed;
}

.custom-checkbox:hover {
  border: 1.67px solid #E42312;
}

.form-check-input:checked {
  background-color: rgba(228, 35, 18, 1);
  border-color: rgba(228, 35, 18, 1);
}

.password-bar > div > div{
  margin-top: 5px;
  height: 4px !important;
}

.lbl-radio{
  display: block;
  border:1px solid #D8DAE5;
  border-radius: 10px;
  padding: 15px;
  padding-left: 50px;
  position: relative;
  cursor: pointer !important;
  height: 100%;
}


.lbl-radio:has(input:checked){
  border:1px solid #BFD7FF !important;
  background-color: #F3F6FF !important;
}
.lbl-radio:has(:disabled){
  border:1px solid #D0D5DD !important;
  background-color: #F2F4F7 !important;
}


.lbl-radio .content-radio .title-radio{
  font-weight: 600;
  font-size: 16px;
  margin-top:5px;
}


.lbl-radio .content-radio .sub-radio{
  opacity: 0.6;
  margin-top: 7px;
  font-size: 12px;
}

.custom-radio-box{
  position: absolute !important;
  left: 20px !important;
  top: 40% !important;
  accent-color: red;
  transform: scale(1.5);
}

.table-legal {
  background-color: #FAFBFD;
  border-radius: 10px;
  border-collapse: separate;
  border-spacing: 0 8px !important;
  padding: 8px !important;
  margin-bottom: 0px;
}

.table-legal thead {
  background-color: #F6F7FC !important;
  margin: 8px !important;
  padding: 8px !important;
}

.table-legal thead th {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #8F95B2 !important;
  padding: 8px;
  margin: 8px 0px !important;
}

.table-legal tbody tr {
  margin: 8px !important;
  padding: 8px !important;
  background-color: white !important;
  border-radius: 10px !important;
}

.table-legal tbody tr:last-child {
  padding-bottom: 0px !important;
}

ol {
  list-style-type: decimal;
}
ol ol{
  list-style-type: lower-latin;
}
ol ol ol{
  list-style-type: lower-roman;
}
ol ol ol ol{
  list-style-type: upper-latin;
}
ol ol ol ol ol{
  list-style-type: upper-roman;
}