.select {
  border-radius: 100px;
}
.values-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.values-container .value {
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    margin: 0 0.55rem 0.55rem 0;
    font-size: 0.9rem;
    color: black;
    background-color: #151c60;
    user-select: none;
}

.values-container .value .button-custom {
    all: unset;
    margin-left: 0.3rem;
    color: black;
    transition: fill 0.15s ease-in-out;
    cursor: pointer;

}

.values-container .value .button-custom:hover {
  color: #bb392d;
}

.values-container .value .button-custom:focus {
  color: #bb392d;
}