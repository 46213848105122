.activeNews {
    color: #00642A;
    text-transform: capitalize;
}
.inactiveNews {
    color: #FFB020;
    text-transform: capitalize;
}

.link-news-text {
    text-decoration: none;
    color: red;
}

.news-list-category{
    color: gray;
}

.news-list-disable-button {
    color: gray;
}

.news-list-delete {
    color: red;
}

.news-list-inactive-button {
    color: #FFB020;
    border: none;
}
.news-list-active-button {
    color: #30D158;
    border: none;
}

.form-switch .form-check-input {
    height: 24px;
    width: 48px;
    background-color: '#FFB020';
}
.form-switch .form-check-input:focus {
    border-color: rgba(0, 0, 0, 0.25);
    outline: 0;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
.form-switch .form-check-input:checked {
    background-color: #30D158;
    border-color: #30D158;
    border: none;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/></svg>");
}
