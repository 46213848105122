.container-image-bg-forgot {
  display: inline;
}

.image-bg-forgot {
  height: 93vh !important;
  width: 100vw;
}

@media (max-width: 992px) {
  .container-image-bg-forgot {
    display: none !important;
  }
}