.text-footer-item {
  color: #2E3032 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.footer-background {
  background: #F6F7FC !important;
}

.text {
  white-space: pre-line;
}