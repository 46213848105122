.title{
    color: red;
}

.text {
    white-space: pre-line;
    text-align: justify;
}
#productHtml table {
    width: 100% !important;
  }