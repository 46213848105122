.container-image-bg-register {
  display: inline;
}

.image-bg-register {
  height: 91.5vh !important;
  width: 100vw;
}

@media (max-width: 992px) {
  .container-image-bg-register {
    display: none !important;
  }
}
