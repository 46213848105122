.text {
    white-space: pre-line;
}

.contact-icon {
    background-color: #EEF0F7;
    background-clip: inherit;
    color: #F44336;
    width: 16px;
    height: 20px;
    border-radius: 50px;
    padding: 15px;
}
.contact-icon-background{
    background-color: #EEF0F7;
    border-radius: 50px;
    height: 42px;
    width: 42px;

}

.text-description  {
    white-space: pre-line;
}