.user-guide-list-container {
  flex: 1;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: 5rem;
  margin-right: 5rem;
}
.landing-apps-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2E3032;
}
.landing-apps-container-width {
  width: 25%;
  padding: 0 0 0 0;
}

.landing-apps-content-margin {
  margin: 0 10rem 5rem 10rem;
}

.card-guide {
  background-color: #F6F7FC;
  border-radius: 12px;
  height: 40vh;
}

.card-guide-text {
  font-size: x-large;
  font-weight: bold;
  overflow: hidden;
}

.card-guide-quote {
  white-space: pre-line;
}

@media (max-width: 800px) {
  .landing-apps-container-width {
    width: 100%;
    padding: 0 20vw 0 20vw;
  }

  .landing-apps-content-margin {
    margin: 0 0 5rem 0;
  }
}